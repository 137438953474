/* Disable the button of the Checkboxmodule until all boxes are checked */

let checkBoxes = document.getElementsByClassName("apprenticeCheck")
let length = checkBoxes.length;
let btn = document.getElementById('apprenticeButton');

function checkCheckBox() {
    for (let i = 0; i < length; i++) {
        if (!checkBoxes[i].checked) {
            btn.classList.add('btn-disabled')
            btn.setAttribute('disabled', 'disabled')
            return;
        }
    }
    btn.classList.remove('btn-disabled');
    btn.setAttribute('disabled', 'false')
}

for (let j = 0; j < length; j++) {
    checkBoxes[j].onclick = function () {
        checkCheckBox()
    }
}

/* Changes the name of the uploadlabel on the "Bewerbungsformular" */

$('input[type="file"]').each(function () {
    let $input = $(this),
        label = $input.siblings('label')[0],
        labelVal = label.innerHTML;

    $input.on('change', function (e) {
        let fileName = '';
        if (this.files && this.files.length > 1) {
            fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
        } else if (e.target.value) {
            fileName = e.target.value.split('\\').pop();
        }

        if (fileName) {
            $(label).find('span')[1].innerHTML = fileName;
        } else {
            $(label).find('span')[1].innerHTML = labelVal;
        }
    });
});

// Fade toTop-Button in and out
$(document).scroll(function() {
    let y = $(this).scrollTop();
    if (y > 300) {
        $('.totop-button').addClass('visible');
    } else {
        $('.totop-button').removeClass('visible');
    }
});

function isIE() {
    let ua = navigator.userAgent;
    let is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    return is_ie;
}

$(document).ready(function () {
    if (isIE()){
        UIkit.modal('#ie-modal').show();
    }
});

